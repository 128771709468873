import React from "react"
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Cv from "../components/cv"

const AboutMePage = () => (
  <Layout>
    <Seo
        title="Psychotherapeutin Katharina Stephan | Verhaltenstherapie München"
        description="Psychologische Psychotherapeutin Katharina Stephan, approbierte Verhaltenstherapeutin. Fundierte psychologische Ausbildung & psychotherapeutische Tätigkeiten"
    />

    <div className="relative">
        <div className="w-full h-[32rem]">
            <StaticImage className="w-full h-full" src="../assets/images/psychotherapeutin-stephan-2.jpg" alt="Therapeutin auf blauem Sessel sitzend lacht herzlich den Patienten an" />
            <div className="absolute top-0 w-full h-full opacity-30 bg-gradient-to-r from-transparent via-black to-transparent"></div>
            <div className="absolute top-0 flex flex-wrap items-center w-full h-full place-items-center">
                <div className="w-full text-center text-white">
                    <h1 className="mb-4 lg:text-4xl">Über mich</h1>
                    <div className="px-4 sm:text-lg">"Vertrauensvolle Zusammenarbeit auf Augenhöhe ist mir wichtig."</div>
                </div>
            </div>
        </div>
    </div>
    <div className="my-12 boxed max-w-prose generic-page">
        <p>Während meiner Tätigkeiten in interdisziplinären Teams war ich in unterschiedlichen Schwerpunkten tätig. Dort arbeiteten wir stets mit großem Engagement, Kooperationsbereitschaft, Zuverlässigkeit und gegenseitiger Wertschätzung. Der interdisziplinäre Austausch über therapeutische Methoden bereichert meine Arbeit und ich freue mich sehr, dies mit meinen Kolleginnen weiterleben zu können.</p>
    </div>
    <div className="grid max-w-6xl gap-1 mx-auto md:grid-cols-2 md:h-screen md:gap-48 " itemscope itemtype="https://schema.org/Person">
        <div className="flex items-center p-4 bg-red-100">
            <StaticImage className="max-w-xs mx-auto transform shadow-md md:shadow-none md:translate-x-32" itemprop="image" src="../assets/images/psychotherapeutin-katharina-stephan_sq.jpg" alt="Psychotherapeutin Katharina Stephan auf dem weißen Fensterbrett sitzend lächelt freundlich in die Kamera" />
        </div>
        <div className="flex items-center">
            <div className="grid w-full max-w-md gap-2 p-4 mx-auto text-center md:gap-6 md:text-left">
                <div>
                    <span className="text-sm" itemprop="jobTitle">M. Sc. Psych.</span><br />
                    <span className="text-2xl tracking-widest bold" itemprop="name">Katharina Stephan</span><br />
                    <span className="" itemprop="hasOccupation" itemscope itemtype="https://schema.org/Occupation">
                        <span itemprop="name">Psychologische Psychotherapeutin</span>
                    </span>
                </div>
                <div>
                    <address className="not-italic">Comeniusstraße&nbsp;1<br />81667&nbsp;München</address>
                </div>
            </div>
        </div>
    </div>
    <div className="my-12 boxed max-w-prose generic-page">
        <p>In meiner Arbeit gehe ich flexibel auf die jeweiligen Bedürfnisse meines Gegenübers ein, eine vertrauensvolle Zusammenarbeit auf Augenhöhe ist mir wichtig. Authentizität und Transparenz sind für mich zentrale Bausteine in der therapeutischen Beziehung. Achtsamkeitsbasierte sowie emotionsfokussierte Techniken fließen von Beginn an mit in die Therapie ein, um eine nachhaltige Veränderung und eine langfristige psychische Gesundheit zu erreichen. Ich arbeite dabei individuell an den jeweiligen Ressourcen orientiert und stelle dabei mein Gegenüber als Ganzes in den Mittelpunkt. Neben der Reduktion von aktuell belastenden Symptomen betrachte ich Psychotherapie als Weg, Verständnis und Akzeptanz für sich selbst und die eigene Erlebenswelt zu fördern. Dabei dürfen auch Humor und Zuversicht in den Prozess einfließen.</p>
        <p>Ich leitete bisher sowohl ambulante als auch stationäre Gruppenangebote, wie z.B. das soziale Kompetenztraining und verschiedene Gruppen in der Depressionsbehandlung. Im Borderline- und Depressionssetting nach der dialektisch-behavioralen Therapie (nach Bohus) konnte ich weitere Erfahrungen sammeln, die ich in meine psychotherapeutische Arbeit einbringe. Mein fachliches Interesse gilt auch der Behandlung von traumaassoziierten Störungen.</p>
    </div>
    <div className="my-12 boxed max-w-prose generic-page">
        <Cv />
    </div>
  </Layout>
)

export default AboutMePage