import React from "react"

export default function Cv() {
  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">Therapeutische Qualifikationen</h3>
        <p className="max-w-2xl mt-1 text-sm text-gray-500">Zertifikate und Fortbildungen</p>
      </div>
      <div className="border-t border-gray-200">
        <dl>
          <div className="px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">2021</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">Fortbildung in Dialektisch Behavioraler Therapie für komplexe posttraumatische Belastungsstörung (DBT-PTSD) bei Prof. Martin Bohus </dd>
          </div>
          <div className="px-4 py-5 bg-white sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">2020</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">Fachliche Befähigung zur Durchführung von Gruppentherapien und Progressiver Muskelentspannung</dd>
          </div>
          <div className="px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Kursleiterzertifikate</dt>
            <div className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <dd className="mt-1">Das Rauchfrei Programm (Tabakentwöhnung)</dd>
                <dd className="mt-1">Der erfolgreiche Umgang mit täglichen Belastungen (Stressbewältigung)</dd>
                <dd className="mt-1">Gruppenprogramm „Abnehmen“</dd>
            </div>
          </div>
        </dl>
      </div>
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">Werdegang</h3>
        <p className="max-w-2xl mt-1 text-sm text-gray-500">Beruflich und akademisch</p>
      </div>
      <div className="border-t border-gray-200">
        <dl>
          <div className="px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">2021 – heute</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"><b>Psychologische Psychotherapeutin</b> in eigener Praxis</dd>
          </div>
          <div className="px-4 py-5 bg-white sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">2020 – 2021</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"><b>Psychologische Psychotherapeutin in der <a href="https://tagesklinik.kirinus.de/standorte/muenchen-nymphenburg" target="_blank" rel="noopener noreferrer">KIRINUS-Tagesklinik München Nymphenburg</a></b> (DBT-Programm im Depressions- und Borderline-Setting)</dd>
          </div>
          <div className="px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">2020</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"><b>Approbation als Psychologische Psychotherapeutin durch die Regierung von Oberbayern</b> , Eintrag in das Arztregister der Kassenärztlichen Vereinigung Bayerns (KVB), Mitglied der Psychotherapeutenkammer Bayern (PTK). LANR: 8973260</dd>
          </div>
          <div className="px-4 py-5 bg-white sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">2015 – 2020</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">Psychotherapeutische Tätigkeit an der Ambulanz der IFT-Gesundheitsförderung GmbH mit dem <b>Schwerpunkt Verhaltenstherapie für Erwachsene</b></dd>
          </div>
          <div className="px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">2016 – 2018</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"><b>Psychologin</b> in der Simssee-Klinik GmbH, Bad Endorf u.a. im Team für chronische Schmerzpatienten und im Team für strukturschwache Patienten</dd>
          </div>
          <div className="px-4 py-5 bg-white sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">2015 –2016</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"><b>Psychologin</b> im <a href="https://kbo-isk.de/kbo-inn-salzach-klinikum/unsere-fachbereiche/psychosomatik" target="_blank" rel="noopener noreferrer">kbo-Inn-Salzach-Klinikum gGmbH</a>, Wasserburg am Inn im Fachbereich Allgemeinpsychiatrie / Psychosomatik sowie der Suchtmedizin</dd>
          </div>
          <div className="px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">2015</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"><b>Psychologin</b> in der <a href="https://www.simssee-klinik.de/behandlungsspektrum/klinik-fuer-psychosomatische-medizin-und-psychotherapie/" target="_blank" rel="noopener noreferrer">Simssee-Klinik GmbH</a>, Bad Endorf im Team für Depressions- und Angstpatienten</dd>
          </div>
          <div className="px-4 py-5 bg-white sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">2012 – 2014</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"><a href="https://www.psychologie.hhu.de/studium-und-lehre-psychologie/master-of-science-studiengang" target="_blank" rel="noopener noreferrer"><b>Master of Science in Psychologie</b> an der Heinrich-Heine-Universität Düsseldorf</a></dd>
          </div>
          <div className="px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">2009 – 2012</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"><a href="https://www.uni-bamberg.de/ba-psychologie/" target="_blank" rel="noopener noreferrer"><b>Bachelor of Science in Psychologie</b> an der Otto-Friedrich-Universität Bamberg</a></dd>
          </div>
        </dl>
      </div>
    </div>
  )
}
